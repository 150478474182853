// config
import config from '@config/config.js'

/**
 * BASE TEXT CONFIG
 */
const mainUrl = config.defaultLang === 'EN' ? 'https://avtelma.com' : "https://avtelma.ru"
const privacyPolicyUrl = config.defaultLang === 'EN' ? "https://avtelma.com/privacy-policy" : "https://avtelma.ru/privacy-policy"
const organizationName = "Avtelma"
const organizationEmail = config.defaultLang === 'EN' ? "support@avtelma.com" : "support@avtelma.ru"

const privacyPolicy = {
    RU: {
        title: "Политика в отношении обработки персональных данных",
        1: {
            title: "1. Общие положения",
            digression: `Настоящая политика обработки персональных данных составлена в соответствии с 
                требованиями Федерального закона от 27.07.2006. №152-ФЗ «О персональных данных» 
                (далее - Закон о персональных данных) и определяет порядок обработки персональных данных 
                и меры по обеспечению безопасности персональных данных, 
                предпринимаемые ${organizationName} (далее – Оператор).`,
            1: `1.1. Оператор ставит своей важнейшей целью и условием осуществления своей деятельности 
                соблюдение прав и свобод человека и гражданина при обработке его персональных данных, 
                в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.`,
            2: `1.2. Настоящая политика Оператора в отношении обработки персональных данных (далее – Политика) 
                применяется ко всей информации, которую Оператор может получить о посетителях веб-сайта ${mainUrl}.`,
        },
        2: {
            title: "2. Основные понятия, используемые в Политике",
            1: `2.1. Автоматизированная обработка персональных данных – обработка 
                персональных данных с помощью средств вычислительной техники.`,
            2: `2.2. Блокирование персональных данных – временное прекращение обработки персональных данных 
                (за исключением случаев, если обработка необходима для уточнения персональных данных).`,
            3: `2.3. Веб-сайт – совокупность графических и информационных материалов, а также программ 
                для ЭВМ и баз данных, обеспечивающих их доступность в сети интернет по сетевому адресу ${mainUrl}.`,
            4: `2.4. Информационная система персональных данных — совокупность содержащихся в 
                базах данных персональных данных, и обеспечивающих их обработку 
                информационных технологий и технических средств.`,
            5: `2.5. Обезличивание персональных данных — действия, в результате которых невозможно определить 
                без использования дополнительной информации принадлежность персональных данных 
                конкретному Пользователю или иному субъекту персональных данных.`,
            6: `2.6. Обработка персональных данных – любое действие (операция) или совокупность действий (операций), 
                совершаемых с использованием средств автоматизации или без использования таких средств с персональными 
                данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), 
                извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, 
                удаление, уничтожение персональных данных.`,
            7: `2.7. Оператор – государственный орган, муниципальный орган, юридическое или физическое лицо, 
                самостоятельно или совместно с другими лицами организующие и (или) осуществляющие обработку 
                персональных данных, а также определяющие цели обработки персональных данных, состав 
                персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.`,
            8: `2.8. Персональные данные – любая информация, относящаяся прямо или косвенно 
                к определенному или определяемому Пользователю веб-сайта ${mainUrl}.`,
            9: `2.9. Персональные данные, разрешенные субъектом персональных данных для распространения, 
                - персональные данные, доступ неограниченного круга лиц к которым предоставлен субъектом 
                персональных данных путем дачи согласия на обработку персональных данных, разрешенных 
                субъектом персональных данных для распространения в порядке, предусмотренном Законом о 
                персональных данных (далее - персональные данные, разрешенные для распространения).`,
            10: `2.10. Пользователь – любой посетитель веб-сайта ${mainUrl}.`,
            11: `2.11. Предоставление персональных данных – действия, направленные на раскрытие 
                персональных данных определенному лицу или определенному кругу лиц.`,
            12: `2.12. Распространение персональных данных – любые действия, направленные на раскрытие 
                персональных данных неопределенному кругу лиц (передача персональных данных) или на ознакомление 
                с персональными данными неограниченного круга лиц, в том числе обнародование персональных данных в 
                средствах массовой информации, размещение в информационно-телекоммуникационных сетях или предоставление 
                доступа к персональным данным каким-либо иным способом.`,
            13: `2.13. Трансграничная передача персональных данных – передача персональных данных на 
                территорию иностранного государства органу власти иностранного государства, иностранному 
                физическому или иностранному юридическому лицу.`,
            14: `2.14. Уничтожение персональных данных – любые действия, в результате которых персональные 
                данные уничтожаются безвозвратно с невозможностью дальнейшего восстановления содержания персональных 
                данных в информационной системе персональных данных и (или) уничтожаются материальные 
                носители персональных данных.`
        },
        3: {
            title: "3. Основные права и обязанности Оператора",
            1: `3.1. Оператор имеет право:
                – получать от субъекта персональных данных достоверные информацию и/или документы, содержащие 
                    персональные данные;
                – в случае отзыва субъектом персональных данных согласия на обработку персональных данных Оператор вправе 
                    продолжить обработку персональных данных без согласия субъекта персональных данных при наличии оснований, 
                    указанных в Законе о персональных данных;
                – самостоятельно определять состав и перечень мер, необходимых и достаточных для обеспечения выполнения 
                    обязанностей, предусмотренных Законом о персональных данных и принятыми в соответствии с ним 
                    нормативными правовыми актами, если иное не предусмотрено Законом о персональных данных 
                    или другими федеральными законами.`,
            2: `3.2. Оператор обязан:
                – предоставлять субъекту персональных данных по его просьбе информацию, касающуюся обработки 
                    его персональных данных;
                – организовывать обработку персональных данных в порядке, установленном действующим законодательством РФ;
                – отвечать на обращения и запросы субъектов персональных данных и их законных представителей в 
                    соответствии с требованиями Закона о персональных данных;
                – сообщать в уполномоченный орган по защите прав субъектов персональных данных по запросу этого 
                    органа необходимую информацию в течение 30 дней с даты получения такого запроса;
                – публиковать или иным образом обеспечивать неограниченный доступ к настоящей Политике в 
                    отношении обработки персональных данных;
                – принимать правовые, организационные и технические меры для защиты персональных данных от 
                    неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, 
                    предоставления, распространения персональных данных, а также от иных неправомерных 
                    действий в отношении персональных данных;
                – прекратить передачу (распространение, предоставление, доступ) персональных данных, прекратить обработку 
                    и уничтожить персональные данные в порядке и случаях, предусмотренных Законом о персональных данных;
                – исполнять иные обязанности, предусмотренные Законом о персональных данных.`
        },
        4: {
            title: "4. Основные права и обязанности субъектов персональных данных",
            1: `4.1. Субъекты персональных данных имеют право:
                – получать информацию, касающуюся обработки его персональных данных, за исключением случаев, 
                    предусмотренных федеральными законами. Сведения предоставляются субъекту персональных данных 
                    Оператором в доступной форме, и в них не должны содержаться персональные данные, относящиеся к 
                    другим субъектам персональных данных, за исключением случаев, когда имеются законные основания 
                    для раскрытия таких персональных данных. Перечень информации и порядок ее получения установлен 
                    Законом о персональных данных;
                – требовать от оператора уточнения его персональных данных, их блокирования или уничтожения в случае, 
                    если персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не 
                    являются необходимыми для заявленной цели обработки, а также принимать предусмотренные законом меры 
                    по защите своих прав;
                – выдвигать условие предварительного согласия при обработке персональных данных в целях 
                    продвижения на рынке товаров, работ и услуг;
                – на отзыв согласия на обработку персональных данных;
                – обжаловать в уполномоченный орган по защите прав субъектов персональных данных или в судебном порядке 
                    неправомерные действия или бездействие Оператора при обработке его персональных данных;
                – на осуществление иных прав, предусмотренных законодательством РФ.`,
            2: `4.2. Субъекты персональных данных обязаны:
                – предоставлять Оператору достоверные данные о себе;
                – сообщать Оператору об уточнении (обновлении, изменении) своих персональных данных.`,
            3: `4.3. Лица, передавшие Оператору недостоверные сведения о себе, либо сведения о другом субъекте 
                персональных данных без согласия последнего, несут ответственность в соответствии с законодательством РФ.`
        },
        5: {
            title: "5. Оператор может обрабатывать следующие персональные данные Пользователя",
            1: `5.1. Фамилия, имя, отчество.`,
            2: `5.2. Электронный адрес.`,
            3: `5.3. Номера телефонов.`,
            4: `5.4. Также на сайте происходит сбор и обработка обезличенных данных о посетителях 
                (в т.ч. файлов «cookie») с помощью сервисов интернет-статистики 
                (Яндекс Метрика и Гугл Аналитика и других).`,
            5: `5.5. Вышеперечисленные данные далее по тексту Политики объединены общим понятием Персональные данные.`,
            6: `5.6. Обработка специальных категорий персональных данных, касающихся расовой, национальной 
                принадлежности, политических взглядов, религиозных или философских убеждений, интимной жизни, 
                Оператором не осуществляется.`,
            7: `5.7. Обработка персональных данных, разрешенных для распространения, из числа специальных категорий 
                персональных данных, указанных в ч. 1 ст. 10 Закона о персональных данных, допускается, если соблюдаются 
                запреты и условия, предусмотренные ст. 10.1 Закона о персональных данных.`,
            8: `5.8. Согласие Пользователя на обработку персональных данных, разрешенных для распространения, 
                оформляется отдельно от других согласий на обработку его персональных данных. При этом соблюдаются условия, 
                предусмотренные, в частности, ст. 10.1 Закона о персональных данных. Требования к содержанию 
                такого согласия устанавливаются уполномоченным органом по защите прав субъектов персональных данных.`,
            '8.1': `5.8.1 Согласие на обработку персональных данных, разрешенных для распространения, 
                Пользователь предоставляет Оператору непосредственно.`,
            '8.2': `5.8.2 Оператор обязан в срок не позднее трех рабочих дней с момента получения указанного согласия 
                Пользователя опубликовать информацию об условиях обработки, о наличии запретов и условий на обработку 
                неограниченным кругом лиц персональных данных, разрешенных для распространения.`,
            '8.3': `5.8.3 Передача (распространение, предоставление, доступ) персональных данных, разрешенных 
                субъектом персональных данных для распространения, должна быть прекращена в любое время по требованию 
                субъекта персональных данных. Данное требование должно включать в себя фамилию, имя, 
                отчество (при наличии), контактную информацию (номер телефона, адрес электронной почты или почтовый адрес)
                субъекта персональных данных, а также перечень персональных данных, обработка которых подлежит прекращению. 
                Указанные в данном требовании персональные данные могут обрабатываться только Оператором, 
                которому оно направлено.`,
            '8.4': `5.8.4 Согласие на обработку персональных данных, разрешенных для распространения, прекращает 
                свое действие с момента поступления Оператору требования, указанного в п. 5.8.3 настоящей 
                Политики в отношении обработки персональных данных.`
        },
        6: {
            title: "6. Принципы обработки персональных данных",
            1: `6.1. Обработка персональных данных осуществляется на законной и справедливой основе.`,
            2: `6.2. Обработка персональных данных ограничивается достижением конкретных, заранее определенных и 
                законных целей. Не допускается обработка персональных данных, несовместимая с целями 
                сбора персональных данных.`,
            3: `6.3. Не допускается объединение баз данных, содержащих персональные данные, обработка которых 
                осуществляется в целях, несовместимых между собой.`,
            4: `6.4. Обработке подлежат только персональные данные, которые отвечают целям их обработки.`,
            5: `6.5. Содержание и объем обрабатываемых персональных данных соответствуют заявленным целям 
                обработки. Не допускается избыточность обрабатываемых персональных данных по отношению к 
                заявленным целям их обработки.`,
            6: `6.6. При обработке персональных данных обеспечивается точность персональных данных, их достаточность, 
                а в необходимых случаях и актуальность по отношению к целям обработки персональных данных. 
                Оператор принимает необходимые меры и/или обеспечивает их принятие по удалению или уточнению 
                неполных или неточных данных.`,
            7: `6.7. Хранение персональных данных осуществляется в форме, позволяющей определить субъекта персональных 
                данных, не дольше, чем этого требуют цели обработки персональных данных, если срок хранения персональных 
                данных не установлен федеральным законом, договором, стороной которого, выгодоприобретателем или 
                поручителем по которому является субъект персональных данных. Обрабатываемые персональные данные 
                уничтожаются либо обезличиваются по достижении целей обработки или в случае утраты необходимости в 
                достижении этих целей, если иное не предусмотрено федеральным законом.`
        },
        7: {
            title: "7. Цели обработки персональных данных",
            1: `7.1. Цель обработки персональных данных Пользователя:
                – информирование Пользователя посредством отправки электронных писем.`,
            2: `7.2. Также Оператор имеет право направлять Пользователю уведомления о новых продуктах и услугах, 
                специальных предложениях и различных событиях. Пользователь всегда может отказаться от получения 
                информационных сообщений, направив Оператору письмо на адрес электронной почты ${organizationEmail} с 
                пометкой «Отказ от уведомлений о новых продуктах и услугах и специальных предложениях».`,
            3: `7.3. Обезличенные данные Пользователей, собираемые с помощью сервисов интернет-статистики, 
                служат для сбора информации о действиях Пользователей на сайте, улучшения качества сайта и его содержания.`
        },
        8: {
            title: "8. Правовые основания обработки персональных данных",
            1: `8.1. Правовыми основаниями обработки персональных данных Оператором являются:
                – уставные (учредительные) документы Оператора;
                – федеральные законы, иные нормативно-правовые акты в сфере защиты персональных данных;
                – согласия Пользователей на обработку их персональных данных, на обработку персональных данных, 
                разрешенных для распространения.`,
            2: `8.2. Оператор обрабатывает персональные данные Пользователя только в случае их заполнения и/или отправки 
                Пользователем самостоятельно через специальные формы, расположенные на сайте ${mainUrl} или 
                направленные Оператору посредством электронной почты. Заполняя соответствующие формы и/или отправляя 
                свои персональные данные Оператору, Пользователь выражает свое согласие с данной Политикой.`,
            3: `8.3. Оператор обрабатывает обезличенные данные о Пользователе в случае, если это разрешено в настройках 
                браузера Пользователя (включено сохранение файлов «cookie» и использование технологии JavaScript).`,
            4: `8.4. Субъект персональных данных самостоятельно принимает решение о предоставлении его 
                персональных данных и дает согласие свободно, своей волей и в своем интересе.`
        },
        9: {
            title: "9. Условия обработки персональных данных",
            1: `9.1. Обработка персональных данных осуществляется с согласия субъекта персональных данных 
                на обработку его персональных данных.`,
            2: `9.2. Обработка персональных данных необходима для достижения целей, предусмотренных международным 
                договором Российской Федерации или законом, для осуществления возложенных законодательством 
                Российской Федерации на оператора функций, полномочий и обязанностей.`,
            3: `9.3. Обработка персональных данных необходима для осуществления правосудия, исполнения судебного акта, 
                акта другого органа или должностного лица, подлежащих исполнению в соответствии с законодательством 
                Российской Федерации об исполнительном производстве.`,
            4: `9.4. Обработка персональных данных необходима для исполнения договора, стороной которого либо 
                выгодоприобретателем или поручителем по которому является субъект персональных данных, 
                а также для заключения договора по инициативе субъекта персональных данных или договора, 
                по которому субъект персональных данных будет являться выгодоприобретателем или поручителем.`,
            5: `9.5. Обработка персональных данных необходима для осуществления прав и законных интересов 
                оператора или третьих лиц либо для достижения общественно значимых целей при условии, 
                что при этом не нарушаются права и свободы субъекта персональных данных.`,
            6: `9.6. Осуществляется обработка персональных данных, доступ неограниченного круга лиц к которым 
                предоставлен субъектом персональных данных либо по его просьбе 
                (далее – общедоступные персональные данные).`,
            7: `9.7. Осуществляется обработка персональных данных, подлежащих опубликованию или обязательному раскрытию 
                в соответствии с федеральным законом.`
        },
        10: {
            title: "10. Порядок сбора, хранения, передачи и других видов обработки персональных данных",
            digression: `Безопасность персональных данных, которые обрабатываются Оператором, обеспечивается путем 
                реализации правовых, организационных и технических мер, необходимых для выполнения в полном объеме 
                требований действующего законодательства в области защиты персональных данных.`,
            1: `10.1. Оператор обеспечивает сохранность персональных данных и принимает все возможные меры, 
                исключающие доступ к персональным данным неуполномоченных лиц.`,
            2: `10.2. Персональные данные Пользователя никогда, ни при каких условиях не будут переданы третьим лицам, 
                за исключением случаев, связанных с исполнением действующего законодательства либо в случае, если субъектом 
                персональных данных дано согласие Оператору на передачу данных третьему лицу для исполнения 
                обязательств по гражданско-правовому договору.`,
            3: `10.3. В случае выявления неточностей в персональных данных, Пользователь может актуализировать их 
                самостоятельно, путем направления Оператору уведомление на адрес электронной почты Оператора 
                ${organizationEmail} с пометкой «Актуализация персональных данных».`,
            4: `10.4. Срок обработки персональных данных определяется достижением целей, для которых были собраны 
                персональные данные, если иной срок не предусмотрен договором или действующим законодательством.
                Пользователь может в любой момент отозвать свое согласие на обработку персональных данных, 
                направив Оператору уведомление посредством электронной почты на электронный адрес Оператора 
                ${organizationEmail} с пометкой «Отзыв согласия на обработку персональных данных».`,
            5: `10.5. Вся информация, которая собирается сторонними сервисами, в том числе платежными системами, 
                средствами связи и другими поставщиками услуг, хранится и обрабатывается указанными лицами (Операторами) 
                в соответствии с их Пользовательским соглашением и Политикой конфиденциальности. Субъект персональных 
                данных и/или Пользователь обязан самостоятельно своевременно ознакомиться с указанными документами. 
                Оператор не несет ответственность за действия третьих лиц, в том числе указанных в настоящем пункте 
                поставщиков услуг.`,
            6: `10.6. Установленные субъектом персональных данных запреты на передачу (кроме предоставления доступа), 
                а также на обработку или условия обработки (кроме получения доступа) персональных данных, разрешенных для 
                распространения, не действуют в случаях обработки персональных данных в государственных, 
                общественных и иных публичных интересах, определенных законодательством РФ.`,
            7: `10.7. Оператор при обработке персональных данных обеспечивает конфиденциальность персональных данных.`,
            8: `10.8. Оператор осуществляет хранение персональных данных в форме, позволяющей определить субъекта 
                персональных данных, не дольше, чем этого требуют цели обработки персональных данных, если срок 
                хранения персональных данных не установлен федеральным законом, договором, стороной которого, 
                выгодоприобретателем или поручителем по которому является субъект персональных данных.`,
            9: `10.9. Условием прекращения обработки персональных данных может являться достижение целей обработки 
                персональных данных, истечение срока действия согласия субъекта персональных данных или отзыв согласия 
                субъектом персональных данных, а также выявление неправомерной обработки персональных данных.`
        },
        11: {
            title: "11. Перечень действий, производимых Оператором с полученными персональными данными",
            1: `11.1. Оператор осуществляет сбор, запись, систематизацию, накопление, хранение, уточнение 
                (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), 
                обезличивание, блокирование, удаление и уничтожение персональных данных.`,
            2: `11.2. Оператор осуществляет автоматизированную обработку персональных данных с получением и/или передачей 
                полученной информации по информационно-телекоммуникационным сетям или без таковой.`
        },
        12: {
            title: "12. Трансграничная передача персональных данных",
            1: `12.1. Оператор до начала осуществления трансграничной передачи персональных данных обязан убедиться в том, 
                что иностранным государством, на территорию которого предполагается осуществлять передачу персональных 
                данных, обеспечивается надежная защита прав субъектов персональных данных.`,
            2: `12.2. Трансграничная передача персональных данных на территории иностранных государств, не отвечающих 
                вышеуказанным требованиям, может осуществляться только в случае наличия согласия в письменной форме 
                субъекта персональных данных на трансграничную передачу его персональных данных и/или исполнения договора, 
                стороной которого является субъект персональных данных.`
        },
        13: {
            title: "13. Конфиденциальность персональных данных",
            digression: `Оператор и иные лица, получившие доступ к персональным данным, обязаны не раскрывать 
                третьим лицам и не распространять персональные данные без согласия субъекта персональных данных, 
                если иное не предусмотрено федеральным законом.`
        },
        14: {
            title: "14. Заключительные положения",
            1: `14.1. Пользователь может получить любые разъяснения по интересующим вопросам, касающимся обработки его 
                персональных данных, обратившись к Оператору с помощью электронной почты ${organizationEmail}.`,
            2: `14.2. В данном документе будут отражены любые изменения политики обработки персональных данных Оператором. 
                Политика действует бессрочно до замены ее новой версией.`,
            3: `14.3. Актуальная версия Политики в свободном доступе расположена в сети Интернет по 
                адресу ${privacyPolicyUrl}.`
        }
    },
    // /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    EN: {
        title: "Policy regarding the processing of personal data",
        1: {
            title: "1. General Provisions",
            digression: `This personal data processing policy has been drawn up in accordance with
                requirements of the Federal Law of 27.07.2006. No. 152-FZ "On Personal Data"
                (hereinafter referred to as the Law on Personal Data) and determines the procedure 
                for processing personal data and measures to ensure the security of personal data,
                undertaken by ${organizationName} (hereinafter referred to as the Operator).`,
            1: `1.1. The operator sets as its most important goal and condition for the implementation of its activities
                observance of the rights and freedoms of a person and a citizen in the processing of his personal data,
                including the protection of the rights to privacy, personal and family secrets.`,
            2: `1.2. This Operator's policy regarding the processing of personal data (hereinafter referred to as the Policy)
                applies to all information that the Operator can obtain about visitors to the website ${mainUrl}.`,
        },
        2: {
            title: "2. Basic concepts used in the Policy",
            1: `2.1. Automated processing of personal data - processing
                personal data using computer technology.`,
            2: `2.2. Blocking of personal data - temporary suspension of the processing of personal data
                (unless the processing is necessary to clarify personal data).`,
            3: `2.3. Website - a set of graphic and informational materials, as well as programs
                for computers and databases that ensure their availability on the Internet at the network address ${mainUrl}.`,
            4: `2.4. Information system of personal data - a set of data contained in
                databases of personal data, and ensuring their processing
                information technology and technical means.`,
            5: `2.5. Depersonalization of personal data - actions as a result of which it is impossible to determine
                without the use of additional information, the ownership of personal data
                specific User or other subject of personal data.`,
            6: `2.6. Processing of personal data - any action (operation) or a set of actions (operations),
                committed with the use of automation tools or without the use of such tools with personal
                data, including collection, recording, systematization, accumulation, storage, clarification (updating, changing),
                extraction, use, transfer (distribution, provision, access), depersonalization, blocking,
                deletion, destruction of personal data.`,
            7: `2.7. Operator - a state body, municipal body, legal or natural person,
                independently or jointly with other persons organizing and (or) carrying out the processing
                personal data, as well as determining the purposes of processing personal data, the composition
                personal data to be processed, actions (operations) performed with personal data.`,
            8: `2.8. Personal data - any information relating directly or indirectly to
                to a specific or identifiable Website User ${mainUrl}.`,
            9: `2.9. Personal data authorized by the subject of personal data for dissemination,
                - personal data, access of an unlimited number of persons to which is provided by the subject
                personal data by giving consent to the processing of personal data permitted
                the subject of personal data for dissemination in the manner prescribed by the Law on
                personal data (hereinafter referred to as personal data permitted for distribution).`,
            10: `2.10. User - any visitor to the website ${mainUrl}.`,
            11: `2.11. Providing personal data - actions aimed at disclosure
                personal data to a certain person or a certain circle of persons.`,
            12: `2.12. Dissemination of personal data - any action aimed at disclosure
                personal data to an indefinite circle of persons (transfer of personal data) or for familiarization
                with personal data of an unlimited number of persons, including the disclosure of personal data in
                mass media, placement in information and telecommunication networks or providing
                access to personal data in any other way.`,
            13: `2.13. Cross-border transfer of personal data - transfer of personal data to
                the territory of a foreign state to an authority of a foreign state, a foreign
                natural or foreign legal entity.`,
            14: `2.14. Destruction of personal data - any actions as a result of which personal data
                data is destroyed irrevocably with the impossibility of further restoration of the content of personal
                data in the personal data information system and (or) material
                carriers of personal data.`
        },
        3: {
            title: "3. Main rights and obligations of the Operator",
            1: `3.1. The operator has the right:
                – receive from the subject of personal data reliable information and / or documents containing
                    Personal Information;
                - in case of withdrawal by the subject of personal data of consent to the processing of personal data, the Operator has the right
                    continue processing personal data without the consent of the subject of personal data if there are grounds,
                    specified in the Personal Data Law;
                – independently determine the composition and list of measures necessary and sufficient to ensure the implementation
                    obligations under the Law on Personal Data and adopted in accordance with it
                    regulatory legal acts, unless otherwise provided by the Law on Personal Data
                    or other federal laws.`,
            2: `3.2. The operator is obliged:
                – provide the subject of personal data, at his request, with information regarding the processing
                    his personal data;
                – organize the processing of personal data in the manner prescribed by the current legislation of the Russian Federation;
                – respond to requests and requests from personal data subjects and their legal representatives in
                    in accordance with the requirements of the Personal Data Law;
                - notify the authorized body for the protection of the rights of subjects of personal data at the request of this
                    body the necessary information within 30 days from the date of receipt of such a request;
                – publish or otherwise provide unrestricted access to this Policy in
                    regarding the processing of personal data;
                – take legal, organizational and technical measures to protect personal data from
                    unauthorized or accidental access to them, destruction, modification, blocking, copying,
                    provision, distribution of personal data, as well as from other illegal
                    actions in relation to personal data;
                – stop the transfer (distribution, provision, access) of personal data, stop processing
                    and destroy personal data in the manner and in the cases provided for by the Law on Personal Data;
                – perform other duties provided for by the Law on Personal Data.`
        },
        4: {
            title: "4. Basic rights and obligations of personal data subjects",
            1: `4.1. Subjects of personal data have the right to:
                – receive information regarding the processing of his personal data, except in cases
                    prescribed by federal laws. Information is provided to the subject of personal data
                    Operator in an accessible form, and they should not contain personal data related to
                    other subjects of personal data, unless there are legal grounds
                    to disclose such personal data. The list of information and the procedure for obtaining it is established
                    Law on Personal Data;
                – require the operator to clarify his personal data, block or destroy them in the event that
                    if personal data is incomplete, outdated, inaccurate, illegally obtained or not
                    are necessary for the stated purpose of the processing, as well as to take legal measures
                    to protect their rights;
                – put forward the condition of prior consent when processing personal data for the purposes of
                    promotion of goods, works and services on the market;
                – to withdraw consent to the processing of personal data;
                – appeal to the authorized body for the protection of the rights of subjects of personal data or in court
                    illegal actions or inaction of the Operator when processing his personal data;
                – to exercise other rights provided for by the legislation of the Russian Federation.`,
            2: `4.2. Subjects of personal data are obliged to:
                – provide the Operator with reliable data about yourself;
                – inform the Operator about the clarification (update, change) of their personal data.`,
            3: `4.3. Persons who provided the Operator with false information about themselves or information about another entity
                personal data without the consent of the latter, are liable in accordance with the legislation of the Russian Federation.`
        },
        5: {
            title: "5. The Operator may process the following personal data of the User",
            1: `5.1. Last name, first name, patronymic.`,
            2: `5.2. Email address.`,
            3: `5.3. Phone numbers.`,
            4: `5.4. The site also collects and processes anonymous data about visitors.
                (including cookies) with the help of Internet statistics services
                (Yandex Metrica and Google Analytics and others).`,
            5: `5.5. The above data further in the text of the Policy are united by the general concept of Personal data.`,
            6: `5.6. Processing of special categories of personal data relating to racial, national
                affiliation, political views, religious or philosophical beliefs, intimate life,
                Not implemented by the operator.`,
            7: `5.7. Processing of personal data permitted for dissemination from among special categories
                personal data specified in Part 1 of Art. 10 of the Law on Personal Data, is allowed if the
                prohibitions and conditions under Art. 10.1 of the Personal Data Law.`,
            8: `5.8. Consent of the User to the processing of personal data permitted for distribution,
                is issued separately from other consents to the processing of his personal data. In doing so, the conditions
                provided, in particular, Art. 10.1 of the Personal Data Law. Content Requirements
                such consent is established by the authorized body for the protection of the rights of subjects of personal data.`,
            '8.1': `5.8.1 Consent to the processing of personal data authorized for dissemination,
                The User provides the Operator directly.`,
            '8.2': `5.8.2 The operator must, no later than three working days from the receipt of said consent
                User to publish information about the conditions of processing, the presence of prohibitions and conditions for processing
                to an unlimited circle of persons of personal data permitted for distribution.`,
            '8.3': `5.8.3 Transfer (distribution, provision, access) of personal data authorized
                subject of personal data for dissemination, must be terminated at any time upon request
                subject of personal data. This requirement must include the last name, first name,
                patronymic (if any), contact information (phone number, e-mail address or postal address)
                the subject of personal data, as well as a list of personal data, the processing of which is subject to termination.
                The personal data specified in this requirement can be processed only by the Operator,
                to which it is directed.`,
            '8.4': `5.8.4 Consent to the processing of personal data authorized for distribution terminates
                its effect from the moment the Operator receives the request specified in clause 5.8.3 of this
                Policies regarding the processing of personal data.`
        },
        6: {
            title: "6. Principles of personal data processing",
            1: `6.1. The processing of personal data is carried out on a lawful and fair basis.`,
            2: `6.2. The processing of personal data is limited to achieving specific, predetermined and
                legitimate purposes. Processing of personal data incompatible with the purposes is not allowed
                collection of personal data.`,
            3: `6.3. It is not allowed to combine databases containing personal data, the processing of which
                carried out for purposes incompatible with each other.`,
            4: `6.4. Only personal data that meet the purposes of their processing are subject to processing.`,
            5: `6.5. The content and scope of the processed personal data correspond to the stated purposes
                processing. The redundancy of the processed personal data in relation to
                the stated purposes of their processing.`,
            6: `6.6. When processing personal data, the accuracy of personal data, their sufficiency,
                and, where necessary, relevance to the purposes of personal data processing.
                The operator takes the necessary measures and / or ensures their adoption to remove or clarify
                incomplete or inaccurate data.`,
            7: `6.7. The storage of personal data is carried out in a form that allows to determine the subject of personal
                data, no longer than required by the purposes of processing personal data, if the period of storage of personal data
                data is not established by federal law, an agreement to which a party, a beneficiary or
                the guarantor for which is the subject of personal data. Processed personal data
                are destroyed or depersonalized upon reaching the purposes of processing or in case of loss of the need for
                achieving these goals, unless otherwise provided by federal law.`
        },
        7: {
            title: "7. Purposes of Personal Data Processing",
            1: `7.1. The purpose of processing the User's personal data:
                – informing the User by sending e-mails.`,
            2: `7.2. The Operator also has the right to send notifications to the User about new products and services,
                special offers and various events. The user can always refuse to receive
                informational messages by sending an email to the Operator at ${organizationEmail} with
                marked "Opt out of notifications about new products and services and special offers."`,
            3: `7.3. Impersonal data of Users collected using Internet statistics services,
                serve to collect information about the actions of Users on the site, improve the quality of the site and its content.`
        },
        8: {
            title: "8. Legal grounds for the processing of personal data",
            1: `8.1. The legal grounds for the processing of personal data by the Operator are:
                – statutory (constituent) documents of the Operator;
                – federal laws, other regulatory legal acts in the field of personal data protection;
                – consent of Users to the processing of their personal data, to the processing of personal data,
                allowed for distribution.`,
            2: `8.2. The Operator processes the User's personal data only if they are filled in and / or sent
                By the user independently through special forms located on the website ${mainUrl} or
                sent to the Operator by e-mail. By filling in the appropriate forms and/or submitting
                their personal data to the Operator, the User agrees with this Policy.`,
            3: `8.3. The Operator processes anonymized data about the User if it is allowed in the settings
                the User's browser (enabled the storage of cookies and the use of JavaScript technology).`,
            4: `8.4. The subject of personal data independently decides to provide it
                personal data and gives consent freely, of his own free will and in his own interest.`
        },
        9: {
            title: "9. Conditions for the processing of personal data",
            1: `9.1. The processing of personal data is carried out with the consent of the subject of personal data
                to the processing of his personal data.`,
            2: `9.2. The processing of personal data is necessary to achieve the goals provided for by the international
                by an agreement of the Russian Federation or by law, for the implementation of the tasks assigned by law
                Russian Federation on the operator of functions, powers and duties.`,
            3: `9.3. The processing of personal data is necessary for the administration of justice, the enforcement of a judicial act,
                act of another body or official subject to execution in accordance with the law
                of the Russian Federation on enforcement proceedings.`,
            4: `9.4. The processing of personal data is necessary for the performance of a contract to which either
                the beneficiary or guarantor of which is the subject of personal data,
                as well as to conclude an agreement on the initiative of the subject of personal data or an agreement,
                for which the subject of personal data will be the beneficiary or guarantor.`,
            5: `9.5. The processing of personal data is necessary for the exercise of rights and legitimate interests
                operator or third parties, or to achieve socially significant goals, provided that
                that this does not violate the rights and freedoms of the subject of personal data.`,
            6: `9.6. Processing of personal data is carried out, access of an unlimited number of persons to which
                provided by the subject of personal data or at his request
                (hereinafter referred to as publicly available personal data).`,
            7: `9.7. Processing of personal data subject to publication or mandatory disclosure
                in accordance with federal law.`
        },
        10: {
            title: "10. The procedure for collecting, storing, transferring and other types of processing of personal data",
            digression: `The security of personal data processed by the Operator is ensured by
                implementation of legal, organizational and technical measures necessary for full implementation
                requirements of the current legislation in the field of personal data protection.`,
            1: `10.1. The operator ensures the safety of personal data and takes all possible measures,
                excluding access to personal data of unauthorized persons.`,
            2: `10.2. The User's personal data will never, under any circumstances, be transferred to third parties,
                with the exception of cases related to the implementation of the current legislation or in the event that the subject
                personal data consent was given to the Operator to transfer data to a third party for execution
                obligations under a civil law contract.`,
            3: `10.3. In case of detection of inaccuracies in personal data, the User can update them
                independently, by sending the Operator a notification to the Operator's e-mail address
                ${organizationEmail} marked "Updating personal data".`,
            4: `10.4. The term for the processing of personal data is determined by the achievement of the purposes for which they were collected
                personal data, unless a different period is provided by the contract or applicable law.
                The user may at any time withdraw his consent to the processing of personal data,
                by sending a notification to the Operator by e-mail to the Operator's e-mail address
                ${organizationEmail} marked "Withdrawal of consent to the processing of personal data".`,
            5: `10.5. All information that is collected by third-party services, including payment systems,
                means of communication and other service providers, is stored and processed by the specified persons (Operators)
                in accordance with their User Agreement and Privacy Policy. Subject of personal
                data and / or the User is obliged to independently familiarize himself with the specified documents in a timely manner.
                The operator is not responsible for the actions of third parties, including those specified in this clause
                service providers.`,
            6: `10.6. Prohibitions on transfer established by the subject of personal data (except for granting access),
                as well as on the processing or processing conditions (other than obtaining access) of personal data authorized for
                distribution, do not apply in cases of processing personal data in public,
                public and other public interests determined by the legislation of the Russian Federation.`,
            7: `10.7. The operator, when processing personal data, ensures the confidentiality of personal data.`,
            8: `10.8. The operator stores personal data in a form that allows you to determine the subject
                personal data, no longer than required by the purposes of processing personal data, if the period
                storage of personal data is not established by federal law, an agreement to which,
                the beneficiary or guarantor for which the subject of personal data is.`,
            9: `10.9. The condition for terminating the processing of personal data may be the achievement of the purposes of processing
                personal data, expiration of the consent of the subject of personal data or withdrawal of consent
                the subject of personal data, as well as the identification of unlawful processing of personal data.`
        },
        11: {
            title: "11. List of actions performed by the Operator with the received personal data",
            1: `11.1. The operator collects, records, systematizes, accumulates, stores, clarifies
                (update, change), extract, use, transfer (distribution, provision, access),
                depersonalization, blocking, deletion and destruction of personal data.`,
            2: `11.2. The operator carries out automated processing of personal data with the receipt and / or transmission
                received information on information and telecommunication networks or without it.`
        },
        12: {
            title: "12. Cross-border transfer of personal data",
            1: `12.1. Before starting a cross-border transfer of personal data, the operator is obliged to make sure that
                that the foreign state, on whose territory it is supposed to carry out the transfer of personal
                data, reliable protection of the rights of personal data subjects is ensured.`,
            2: `12.2. Cross-border transfer of personal data on the territory of foreign states that do not meet
                the above requirements, can only be carried out if there is consent in writing
                the subject of personal data for the cross-border transfer of his personal data and / or the execution of the contract,
                to which the subject of personal data is a party.`
        },
        13: {
            title: "13. Confidentiality of personal data",
            digression: `The operator and other persons who have gained access to personal data are required not to disclose
                to third parties and not to distribute personal data without the consent of the subject of personal data,
                unless otherwise provided by federal law.`
        },
        14: {
            title: "14. Final Provisions",
            1: `14.1. The user can receive any clarifications on issues of interest regarding the processing of his
                personal data by contacting the Operator via e-mail ${organizationEmail}.`,
            2: `14.2. This document will reflect any changes in the policy of processing personal data by the Operator.
                The policy is valid indefinitely until it is replaced by a new version.`,
            3: `14.3. The current version of the Policy in the public domain is located on the Internet at
                address ${privacyPolicyUrl}.`
        }
    }
}


export default privacyPolicy